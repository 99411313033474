import preArrow from "assets/image/left_btn.png";
import nextArrow from "assets/image/right_btn.png";
import SectionWrapper from "./SectionWrapper";
import { winningData } from "CONSTANTS/footballwinnerList2023";
import Slider from "react-slick";

const styles = {
  wrapper:
    "rounded-[24px] bg-white-0.7 border-2 border-green-1 py-[24px] sm:py-[40px] w-full px-[calc((100%-323px)/2)] sm:px-[92px] xl:px-[50px] 1340:px-[72px] mb-[40px] last:mb-0",
  section:
    "py-[24px] sm:py-[40px] first:pt-0 last:pb-0  border-b-[1px]  sm:border-b-[2px] border-[rgba(255,255,255,.3)]  last:border-none effectLink",
  breakline: "bg-black-0.3 w-full h-px my-6 xl:h-0.5 xl:my-10",
};
const NextArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <button
      className={`w-[40px] h-[40px] sm:w-[60px] sm:h-[60px]  z-10 ${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={nextArrow} alt="next-arrow" />
    </button>
  );
};
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      className={`w-[40px] h-[40px] sm:w-[60px] sm:h-[60px] ${className} z-10`}
      style={{
        ...style,
      }}
      onClick={(e) => {
        onClick(e);
      }}
    >
      <img src={preArrow} alt="prev-arrow" />
    </button>
  );
};

const ResultList = () => {
  const settings = {
    lazyLoad: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  // console.log(winningData["最佳行銷推廣"][0]);
  return (
    <div className="px-[3.75%] sm:px-[12.5%] text-white pb-[96px] sm:pb-[128px]">
      <div className={`${styles.wrapper}`}>
        <Slider {...settings}>
          {winningData["最佳行銷推廣"][0].map((_, i) => {
            return (
              <SectionWrapper
                prize={"最佳行銷推廣"}
                key={i}
                companyIndex={i}
                currIndex={0}
              />
            );
          })}
        </Slider>
        <div className={styles.breakline} />
        <Slider {...settings}>
          {winningData["最佳行銷推廣"][1].map((_, i) => {
            return (
              <SectionWrapper
                prize={"最佳行銷推廣"}
                key={i}
                companyIndex={i}
                currIndex={1}
              />
            );
          })}
        </Slider>
        <div className={styles.breakline} />
        <Slider {...settings}>
          {winningData["最佳行銷推廣"][2].map((_, i) => {
            return (
              <SectionWrapper
                prize={"最佳行銷推廣"}
                key={i}
                companyIndex={i}
                currIndex={2}
              />
            );
          })}
        </Slider>
      </div>
      <div className={`${styles.wrapper}`}>
        <Slider {...settings}>
          {winningData["最佳娛樂效果"][0].map((_, i) => {
            return (
              <SectionWrapper
                prize={"最佳娛樂效果"}
                key={i}
                companyIndex={i}
                currIndex={0}
              />
            );
          })}
        </Slider>
      </div>
      <div className={`${styles.wrapper}`}>
        <Slider {...settings}>
          {winningData["最佳動作指導"][0].map((_, i) => {
            return (
              <SectionWrapper
                prize={"最佳動作指導"}
                key={i}
                companyIndex={i}
                currIndex={0}
              />
            );
          })}
        </Slider>
        <div className={styles.breakline} />
        <Slider {...settings}>
          {winningData["最佳動作指導"][1].map((_, i) => {
            return (
              <SectionWrapper
                prize={"最佳動作指導"}
                key={i}
                companyIndex={i}
                currIndex={1}
              />
            );
          })}
        </Slider>
        <div className={styles.breakline} />
        <Slider {...settings}>
          {winningData["最佳動作指導"][2].map((_, i) => {
            return (
              <SectionWrapper
                prize={"最佳動作指導"}
                key={i}
                companyIndex={i}
                currIndex={2}
              />
            );
          })}
        </Slider>
      </div>
    </div>
  );
};
export default ResultList;

// foootball-tiktok-frontend
