import { winningData } from "CONSTANTS/footballwinnerList2023";
import React, { Suspense, useRef } from "react";
import footballIcon from "assets/image/FOOTBALL icon.png";
const Tiktok = React.lazy(() => import("./Tiktok"));
const styles = {
  title:
    "text-18px xl:text-30px font-bold mb-[6px] sm:mb-[10px] whitespace-pre-wrap max-w-[238px] sm:max-w-none text-center xl:text-start",
};
const VideoSection = ({
  prize,
  currIndex,
  companyIndex = 0,
  videoIndex = 0, // vidoe array (only index 0)
  isUser = false,
}) => {
  const componentRef = useRef();
  const companyArray = winningData[prize][currIndex];

  const companyData = companyArray[companyIndex];
  const videoData = companyData?.video?.[videoIndex];
  // console.log(companyIndex, companyData, videoData);
  return (
    <Suspense fallback={<div className="h-[200px]"></div>}>
      <div
        className={`flex flex-col-reverse items-center xl:flex-row xl:items-start`}
        ref={componentRef}
      >
        <>
          <Tiktok
            id={videoData.id}
            url={videoData.url}
            height={videoData.height ? videoData.height : 739}
          />
          <div className="mb-[12px] xl:mb-0  xl:ml-[56px] flex-grow flex xl:block flex-col items-center ">
            <div
              className={`text-24px sm:text-36px flex gap-2 items-center mb-[16px] xl:mb-[64px] font-black ml-[-4px] `}
            >
              <div className="sm:pt-[6px] pt-[4px]">
                <img
                  src={footballIcon}
                  alt="football-icon"
                  className="w-6 h-6 sm:w-7 sm:h-7 xl:w-[34px] xl:h-[34px]"
                />
              </div>

              <div className="text-green-1 text-[24px] sm:text-28px xl:text-36px">
                {" "}
                {prize}{" "}
              </div>
            </div>
            <p className={styles.title}>{companyData.typeName}</p>
            <p className="text-14px sm:text-18px xl:text-20px font-medium max-w-[238px] sm:max-w-none text-center xl:text-start lg:whitespace-normal whitespace-pre-wrap">
              創作者: {companyData.artistName}
            </p>
          </div>
        </>
      </div>
    </Suspense>
  );
};

export default VideoSection;
