import React, { useState } from "react";
import instagramIcon from "../assets/image/IG icon.png";
import facebookIcon from "../assets/image/FB icon.png";
import lineIcon from "../assets/image/LINE icon.png";
import footballLogo from "../assets/image/football_logo.png";

const styles = {
  link: "w-full h-full block",
};

const Navbar = () => {
  const handleLogoClick = () => {
    window.gtag("event", "header_click", {
      event_label: "header挺足球logo",
    });
  };
  const handleSocialClick = (icon) => {
    if (icon === "ig") {
      window.gtag("event", "social_click", {
        event_label: "ig",
        value: "ig",
      });
    } else if (icon === "fb") {
      window.gtag("event", "social_click", {
        event_label: "fb",
        value: "fb",
      });
    } else {
      window.gtag("event", "social_click", {
        event_label: "line",
        value: "line",
      });
    }
  };
  return (
    <div className="w-full bg-white-1 text-black-1 shadow-nav fixed z-30 h-[70px] flex items-center justify-between px-4 sm:px-[6.67%]">
      <a
        href="https://wooo.tw/9ucdd2E"
        className="w-[105px] h-auto"
        onClick={handleLogoClick}
      >
        <img src={footballLogo} alt="catIcon"></img>
      </a>
      <div className="flex gap-5 items-center w-[120px]">
        <a
          href={`https://wooo.tw/0gWjVe3`}
          target="_blank"
          rel="noreferrer"
          className={styles.link}
          onClick={() => handleSocialClick("ig")}
        >
          <img
            src={instagramIcon}
            alt="instagramIcon"
            className="w-6 h-6"
          ></img>
        </a>
        <a
          href={`https://wooo.tw/x5cJkaG`}
          target="_blank"
          rel="noreferrer"
          className={styles.link}
          onClick={() => handleSocialClick("fb")}
        >
          <img src={facebookIcon} alt="facebookIcon" className="w-6 h-6"></img>
        </a>
        <a
          href={`https://wooo.tw/1JTebte`}
          target="_blank"
          rel="noreferrer"
          className={styles.link}
          onClick={() => handleSocialClick("line")}
        >
          <img src={lineIcon} alt="lineIcon" className="w-6 h-6"></img>
        </a>
      </div>
    </div>
  );
};

export default Navbar;
