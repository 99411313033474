import Home from "./pages";

import { Routes, Route, Navigate } from "react-router-dom";
// import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "style/slider.css";
import "style/loader.css";
import "style/hamburgerMenu.css";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/2023" />} />
      <Route path="2023" element={<Home />} />
    </Routes>
  );
}

export default App;
