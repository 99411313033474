export const winningData = {
  最佳行銷推廣: [
    [
      {
        id: "1-1",
        typeName: "球鞋開箱",
        artistName: "skillscrewhd",
        video: [
          {
            type: "video",
            url: "https://www.tiktok.com/@skillscrewhd/video/7230779553554156826",
            id: "7230779553554156826",
            height: 755,
          },
        ],
      },
      {
        id: "1-2",
        typeName: "球鞋開箱",
        artistName: "dreamsport_football",
        video: [
          {
            type: "video",
            url: "https://www.tiktok.com/@dreamsport_football/video/7309123533165219077",
            id: "7309123533165219077",
            height: 755,
          },
        ],
      },
    ],
    [
      {
        id: "2-1",
        typeName: "足球開箱",
        artistName: "skillscrewhd",
        video: [
          {
            type: "video",
            url: "https://www.tiktok.com/@skillscrewhd/video/7215931832255155462",
            id: "7215931832255155462",
            height: 755,
          },
        ],
      },
      {
        id: "2-2",
        typeName: "足球開箱",
        artistName: "adidas",
        video: [
          {
            type: "video",
            url: "https://www.tiktok.com/@adidas/video/7302777644276272417",
            id: "7302777644276272417",
            height: 755,
          },
        ],
      },
    ],
    [
      {
        id: "3-1",
        typeName: "足球接力",
        artistName: "NIKE",
        video: [
          {
            type: "video",
            url: "https://www.tiktok.com/@nike/video/7295864236146707754",
            id: "7295864236146707754",
            height: 755,
          },
        ],
      },
      {
        id: "3-2",
        typeName: "足球接力",
        artistName: "NIKE",
        video: [
          {
            type: "video",
            url: "https://www.tiktok.com/@nike/video/7292520265571978542",
            id: "7292520265571978542",
            height: 755,
          },
        ],
      },
    ],
  ],
  最佳娛樂效果: [
    [
      {
        id: "4-1",
        typeName: "特效耍球",
        artistName: "yupao_freestyle",
        video: [
          {
            type: "video",
            url: "https://www.tiktok.com/@yupao_freestyle/video/6723453970133585153",
            id: "6723453970133585153",
            height: 755,
          },
        ],
      },
      {
        id: "4-2",
        typeName: "花式進球",
        artistName: "REALMADRID",
        video: [
          {
            type: "video",
            url: "https://www.tiktok.com/@realmadrid/video/6980976057130175750",
            id: "6980976057130175750",
            height: 755,
          },
        ],
      },
      {
        id: "4-3",
        typeName: "火焰進球",
        artistName: "yobryans",
        video: [
          {
            type: "video",
            url: "https://www.tiktok.com/@yobryans/video/7276296822597979397?q=MESSI%20GOALS&t=1709628780074",
            id: "7276296822597979397",
            height: 755,
          },
        ],
      },
      {
        id: "4-4",
        typeName: "火箭足球",
        artistName: "REALMADRID",
        video: [
          {
            type: "video",
            url: "https://www.tiktok.com/@realmadrid/video/7320551387488668961",
            id: "7320551387488668961",
            height: 755,
          },
        ],
      },
      {
        id: "4-5",
        typeName: "個人耍球",
        artistName: "happyfootballlife",
        video: [
          {
            type: "video",
            url: "https://www.tiktok.com/@happyfootballlife/video/7204863150242401563?_r=1&_t=8k5gIfGpJC6",
            id: "7204863150242401563",
            height: 755,
          },
        ],
      },
      {
        id: "4-6",
        typeName: "閃電過人",
        artistName: "REALMADRID",
        video: [
          {
            type: "video",
            url: "https://www.tiktok.com/@realmadrid/video/7306436690510384416",
            id: "7306436690510384416",
            height: 755,
          },
        ],
      },
    ],
  ],
  最佳動作指導: [
    [
      {
        id: "5-1",
        typeName: "彩虹戲球",
        artistName: "quinskillz",
        video: [
          {
            type: "video",
            url: "https://www.tiktok.com/@quinskillz/video/7278575737941150978",
            id: "7278575737941150978",
            height: 755,
          },
        ],
      },
    ],
    [
      {
        id: "6-1",
        typeName: "足球動作教學",
        artistName: "tamakou88",
        video: [
          {
            type: "video",
            url: "https://www.tiktok.com/@tamakou88/video/7324706893140610322",
            id: "7324706893140610322",
            height: 755,
          },
        ],
      },
      {
        id: "6-2",
        typeName: "足球動作教學",
        artistName: "tamakou88",
        video: [
          {
            type: "video",
            url: "https://www.tiktok.com/@tamakou88/video/7277923965329362184?q=%E8%B6%B3%E7%90%83&t=1706859635515",
            id: "7277923965329362184",
            height: 755,
          },
        ],
      },
    ],
    [
      {
        id: "7-1",
        typeName: "挑球教學",
        artistName: "yo.jing",
        video: [
          {
            type: "video",
            url: "https://www.tiktok.com/@yo.jing/video/7279769263848656133",
            id: "7279769263848656133",
            height: 755,
          },
        ],
      },
      {
        id: "7-2",
        typeName: "挑球教學",
        artistName: "yo.jing",
        video: [
          {
            type: "video",
            url: "https://www.tiktok.com/@yo.jing/video/7276431836241349893",
            id: "7276431836241349893",
            height: 755,
          },
        ],
      },
    ],
  ],
};
