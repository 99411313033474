import facebookImage from "assets/image/FB icon.png";
import lineImage from "assets/image/LINE icon.png";

const styles = {
  link: "w-[40px] h-[40px] mr-[8px] block last:mr-0",
};

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="text-xs xl:text-sm pt-[18px] sm:pt-[16px] pb-[45px] sm:pb-[52px] flex flex-col sm:flex-row justify-center items-center border-t border-black-1 mx-[13px] sm:mx-[80px] w-[92.08%] sm:w-[88.89%]">
      &copy; {year} All Rights Reserved, 挺足球
    </footer>
  );
};

export default Footer;
