import Footer from "../components/Footer";
import Hero from "../components/Hero";
import ResultList from "../components/ResultList";
import Navbar from "components/Navbar";

const topParagraph = {
  title: "只要你想秀、敢秀，TikTok能為你找到最適合的目標族群",
  subtitle:
    "全球超過10億用戶使用的TikTok，透過短影音能讓受眾一手滑到你想「SHOW」的足球資訊，商品開箱、足球課程、比賽活動或是吸睛的花式足球。",
};
const bottomParagraph = {
  title: "想瞭解更優惠、更貼近商品特性的服務，歡迎您與我們聯繫",
};

const Home = () => {
  const handleButtonClick = (label) => {
    console.log(label);
    if (label === "瞭解更多") {
      window.gtag("event", "more_click", {
        event_label: { label },
      });
    } else {
      window.gtag("event", "contact_click", {
        event_label: { label },
      });
    }
  };
  return (
    <div>
      <Navbar />
      <section className="pt-[50px] sm:pt-[70px] bg-black">
        <Hero />
      </section>
      <div className="bg-webBackground bg-[length:100%_auto] bg-repeat-y bg-center flex flex-col items-center">
        <div className="my-20 w-3/4 mx-auto max-w-[845px]">
          <p className="text-18px sm:text-22px xl:text-32px font-bold mb-[6px] sm:mb-[10px] text-center">
            {topParagraph.title}
          </p>
          <p className="text-14px sm:text-20px xl:text-24px font-medium text-center lg:whitespace-normal">
            {topParagraph.subtitle}
          </p>
          <div className="w-full flex justify-center mt-6">
            <a
              href="https://www.dancingcats.com.tw/"
              target="_blank"
              rel="noreferrer"
              className="bg-green-1 text-white-1 text-lg font-medium rounded-lg px-6 py-1.5 xl:rounded-2xl"
              onClick={() => handleButtonClick("瞭解更多")}
            >
              瞭解更多
            </a>
          </div>
        </div>
        <div className="max-w-[1440px] w-full">
          <ResultList />
        </div>
        <div className="mb-[136px] w-3/4 mx-auto max-w-[845px]">
          <p className="text-18px sm:text-22px xl:text-32px font-bold mb-[6px] sm:mb-[10px] whitespace-pre-wrap text-center">
            {bottomParagraph.title}
          </p>
          <div className="w-full flex justify-center mt-6">
            <a
              href="mailto:service@foootball.cc"
              className="bg-green-1 text-white-1 text-lg font-medium rounded-lg px-6 py-1.5 xl:rounded-2xl"
              onClick={() => handleButtonClick("聯絡我們")}
            >
              聯絡我們
            </a>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
